@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/******************* getting rid of the padding on cols. pbootstrap has a class for this so prob not needed *******************/

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 0;
  padding-right: 0;
}

/******************* setting up the spacing system used throughout *******************/

.logo {
  width: 120px;
}

.navbar-light .navbar-toggler {
  border: none;
}
@media only screen and (min-width: 996px) {
  .nav-item {
    margin: 0 1rem;
  }
}

@media only screen and (max-width: 996px) {
  .navbar-collapse {
    margin-top: 3rem;
  }
}

/* each section is wrapped in a container-wrapper */

.container-wrapper {
  padding: 1rem;
}

@media only screen and (min-width: 1200px) {
  .container-wrapper {
    padding: 3rem 3rem;
  }
}

/* each module in a column is wrapped in an element-wrapper */

.element-wrapper {
  padding: 1rem;
}

@media only screen and (min-width: 996px) {
  .element-wrapper {
    padding: 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .element-wrapper {
    padding: 3rem;
  }
}

/******************* hero *******************/

.hero {
  position: relative;
}

.hero-box {
  position: absolute;
  z-index: 10;
  color: white;
  bottom: 0rem;
  left: 0rem;
}

/******************* colours *******************/

.bg-mid-grey {
  background-color: #ccc;
}
.bg-light-grey {
  background-color: #ebebeb;
}
.bg-dark-grey {
  background-color: #999;
}
.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: rgb(32, 54, 99);
}

.white {
  color: white;
}

.red {
  color: red;
}

.blue {
  color: rgb(32, 54, 99);
}

.bg-lightblue {
  background-color: #b3d4fc;
}
.bg-offwhite {
  background-color: #f1fafd;
}

/******************* helpers *******************/

.sticky-top {
  padding-top: 6rem;
}

.row-top {
  padding-top: 0.5rem;
}

.row-bottom {
  padding-top: 0.5rem;
}

ul {
  margin: 0;
  padding: 0;
}

.border-top {
  border-top: solid 1px #ccc;
}
.footer {
  border-top: solid 1px #ccc;
}

/********************** Buttons ***********************/

.btn-primary {
  color: #fff;
  background-color: rgb(32, 54, 99);
  border-color: #fff;
}

.a-white {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  /* border-bottom:  */
}
.a-white:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.success-message {
  padding-top: 1rem;
}

.btn-create {
  margin: 0.5rem;
}

.detail-text {
        margin-bottom: 0.5rem;
}

.body-text {
  word-wrap: break-word;
}

/* load fonts in here  */


/*@font-face {
  font-family: "UntitledSansWeb-Regular";
  src: url("../fonts/untitled/UntitledSansWeb-Regular.eot"),
  url("../fonts/untitled/UntitledSansWeb-Regular.woff") format("woff"),
  url("../fonts/untitled/UntitledSansWeb-Regular.otf") format("opentype");

}*/



/* made using https://type-scale.com/ */

/***************************************** setting the base size */

html {
  font-size: 12px;
  -webkit-font-smoothing: subpixel-antialiased;
 
}

@media only screen and (min-width: 996px) {
        html {font-size: 14px;}
}
/*
@media only screen and (min-width: 1200px) {
        html {font-size: 16px;}
}
*/

/*************************************** type styles */

body {
  background-color: white;
   font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.45;
  color: rgb(32,54,99);
  
}

p {
  margin-bottom: 1.25em;

}

h1, h2, h3, h4, h5 {
  margin: 0rem 0 1.5rem 0;
  line-height: 1.3;
  font-weight: 600;
}

h1 {font-size: 1.8rem; margin-top: 0; line-height: 1.2; margin-bottom: 2rem;}
h2 {font-size: 1.6rem;}
h3 {font-size: 1.4rem;}
h4 {font-size: 1.2rem;}
h5 {font-size: 1rem; line-height: 1.45;}
h6 {font-size: 0.7em;}
p {font-size: 1.25rem;}
.intro { line-height: 1.45; font-size: 1.25rem; color: #555;}
small, .text_small {font-size: 0.75em;}
.jumbo {font-size: 4rem;}





@media only screen and (min-width: 996px) {
        h1 {font-size: 3rem; margin-top: 0;}
        h2 {font-size: 2.4rem;}
        h3 {font-size: 2.0rem;}
        h4 {font-size: 1.6rem;}
        h5 {font-size: 1rem; }
        h6 {font-size: 0.7rem;}
        p {font-size: 1.3rem;}
        .intro { line-height: 1.45; font-size: 1.5rem; color: #555;}
        small, .text_small {font-size: 0.75em;}
        .jumbo {font-size: 4rem;}
}


@media only screen and (min-width: 1200px) {
        h1 {font-size: 4rem; margin-top: 0;}
        h2 {font-size: 3.0rem;}
        h3 {font-size: 2.4rem;}
        h4 {font-size: 1.6rem;}
        h5 {font-size: 1rem;}
        h6 {font-size: 0.8rem;}
        p {font-size: 1.3rem;}
        .intro { line-height: 1.45; font-size: 1.5rem; color: #555;}
        small, .text_small {font-size: 0.75em;}
        .jumbo {font-size: 4rem;}
}



