/* load fonts in here  */


/*@font-face {
  font-family: "UntitledSansWeb-Regular";
  src: url("../fonts/untitled/UntitledSansWeb-Regular.eot"),
  url("../fonts/untitled/UntitledSansWeb-Regular.woff") format("woff"),
  url("../fonts/untitled/UntitledSansWeb-Regular.otf") format("opentype");

}*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");



/* made using https://type-scale.com/ */

/***************************************** setting the base size */

html {
  font-size: 12px;
  -webkit-font-smoothing: subpixel-antialiased;
 
}

@media only screen and (min-width: 996px) {
        html {font-size: 14px;}
}
/*
@media only screen and (min-width: 1200px) {
        html {font-size: 16px;}
}
*/

/*************************************** type styles */

body {
  background-color: white;
   font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.45;
  color: rgb(32,54,99);
  
}

p {
  margin-bottom: 1.25em;

}

h1, h2, h3, h4, h5 {
  margin: 0rem 0 1.5rem 0;
  line-height: 1.3;
  font-weight: 600;
}

h1 {font-size: 1.8rem; margin-top: 0; line-height: 1.2; margin-bottom: 2rem;}
h2 {font-size: 1.6rem;}
h3 {font-size: 1.4rem;}
h4 {font-size: 1.2rem;}
h5 {font-size: 1rem; line-height: 1.45;}
h6 {font-size: 0.7em;}
p {font-size: 1.25rem;}
.intro { line-height: 1.45; font-size: 1.25rem; color: #555;}
small, .text_small {font-size: 0.75em;}
.jumbo {font-size: 4rem;}





@media only screen and (min-width: 996px) {
        h1 {font-size: 3rem; margin-top: 0;}
        h2 {font-size: 2.4rem;}
        h3 {font-size: 2.0rem;}
        h4 {font-size: 1.6rem;}
        h5 {font-size: 1rem; }
        h6 {font-size: 0.7rem;}
        p {font-size: 1.3rem;}
        .intro { line-height: 1.45; font-size: 1.5rem; color: #555;}
        small, .text_small {font-size: 0.75em;}
        .jumbo {font-size: 4rem;}
}


@media only screen and (min-width: 1200px) {
        h1 {font-size: 4rem; margin-top: 0;}
        h2 {font-size: 3.0rem;}
        h3 {font-size: 2.4rem;}
        h4 {font-size: 1.6rem;}
        h5 {font-size: 1rem;}
        h6 {font-size: 0.8rem;}
        p {font-size: 1.3rem;}
        .intro { line-height: 1.45; font-size: 1.5rem; color: #555;}
        small, .text_small {font-size: 0.75em;}
        .jumbo {font-size: 4rem;}
}


